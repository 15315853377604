// history functions
export function pushItemInHistory(props, path, stateObj) {
	if (stateObj && Object.keys(stateObj).length) {
		props.history.push({ pathname: path, state: stateObj });
	} else {
		props.history.push({ pathname: path });
	}
}

export function replaceItemInHistory(props, path) {
	props.history.replace(path);
}

// local storage functions
export function setLocalStorageItem(key, value) {
	localStorage.setItem(key, value);
}

export function fetchLocalStorageItem(key) {
	return localStorage.getItem(key);
}

export function removeLocalStorageItem(key) {
	localStorage.removeItem(key);
}

export function clearLocalStorage() {
	localStorage.clear();
}

// session storage functions
export function setSessionStorageItem(key, value, isJson = false) {
	if (isJson) {
		sessionStorage.setItem(key, JSON.stringify(value));
	} else {
		sessionStorage.setItem(key, value);
	}
}

export function fetchSessionStorageItem(key, isJson = false) {
	if (isJson) {
		return JSON.parse(sessionStorage.getItem(key));
	} else {
		return sessionStorage.getItem(key);
	}
}

export function removeSessionStorageItem(key) {
	sessionStorage.removeItem(key);
}
