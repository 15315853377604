export const WELCOME = '/welcome';
export const HOMEPAGE = '/';
export const ACCOUNT_LOGIN = '/login';
export const ACCOUNT_SIGNUP = '/signup';
export const REWARDS = '/rewards';
export const FEEDBACK = '/feedback';

// REWARD DETAIL PAGE
export const REWARD_DETAIL_GIFTCARD_PAGE = "rewards/giftcard/:id/:slugId";
export const REWARD_DETAIL_DISCOUNTOFFER_PAGE = "rewards/discount/:id/:slugId";
export const REWARD_DETAIL_CASHBACK_PAGE = "rewards/cashback/:id/:slugId";

// ORDER SUMMARY PAGE
export const ORDER_SUMMARY_PAGE = "order/:offerType/:id";

export const PROFILE = '/profile';
export const PROFILE_DETAILS = '/profile/details';
export const PROFILE_LOYALTY_POINTS = '/profile/loyalty-points';
export const PROFILE_MY_REWARDS = '/profile/my-rewards';
export const PROFILE_REDEEMED_OFFERS = '/profile/redeemed-offers';
export const PROFILE_INVOICE = '/profile/invoice';
export const PROFILE_FEEDBACK = '/profile/feedback';
export const PROFILE_CAMPAIGN = '/profile/campaign';
export const PROFILE_TNC = '/profile/terms-of-use';
export const PROFILE_PRIVACY_POLICY = '/profile/privacy-policy';
export const PROFILE_CONTACT_US = '/profile/contact-us';