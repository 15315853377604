import { FeedbackTabIcon, HomeTabIcon, ProfileTabIcon, RewardsTabIcon } from "../assets/svg";
import { FEEDBACK, HOMEPAGE, PROFILE, REWARDS } from "../routes/RoutesConstant";

const IST_OFFSET = 330;
const DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_DATE_FORMAT = 'ddd, DD MMM';

export const TABS = [
  {key: 'home', Icon: HomeTabIcon, text: 'Home', url: HOMEPAGE},
  {key: 'rewards',Icon: RewardsTabIcon,text: 'Rewards',url: REWARDS},
  {key: 'feedback',Icon: FeedbackTabIcon,text: 'Feedback',url: FEEDBACK},
  {key: 'profile',Icon: ProfileTabIcon,text: 'Profile',url: PROFILE},
]

export const GENDER = [
  {key: 'male', value: 'male', text: 'Male', subText: ''},
  {key: 'female', value: 'female', text: 'Female', subText: ''},
  {key: 'others', value: 'others', text: 'Others', subText: ''}
]

export { IST_OFFSET, DATE_FORMAT, DISPLAY_DATE_FORMAT };
