import styled from "styled-components";

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 4.8rem;
    color: #ED1C24;
    font-size: 1.8rem;
    gap: 1.6rem;
  }

  .error-message {
    font-size: 2.4rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    cursor: pointer;
  }

  .error-text {
    color: #ED1C24;
  }

  .error-data {
    color: #ED1C24;
  }
`;
