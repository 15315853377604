import { createContext, useEffect, useState } from 'react';
import { fetchLocalStorageItem, setLocalStorageItem } from '../utils/StorageUtils';

const initialState = {
  campaignId: 1,
  storeId: 1,
};

export const AuthContext = createContext({
  ...initialState,
});

export const AuthProvider = ({ children }) => {
  // intially set the campaign Id and store id 1 and 294 for demo purpose
  const [campaignId, setCampaignId] = useState(fetchLocalStorageItem('campaignId'));
  const [storeId, setStoreId] = useState(fetchLocalStorageItem('storeId'));
  const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
  const [userData, setUserData] = useState(JSON.parse(fetchLocalStorageItem('userData')) || {});
  const [brandData, setBrandData] = useState(JSON.parse(fetchLocalStorageItem('brandData')) || {});
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize isAuthenticated from localStorage
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    setLocalStorageItem('campaignId', campaignId);
  }, [campaignId]);

  useEffect(() => {
    setLocalStorageItem('storeId', storeId);
  }, [storeId]);

  const storedToken = localStorage.getItem('token');

  useEffect(() => {
    if (storedToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [storedToken]);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData', {});
    localStorage.removeItem('isPageReloaded');

    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ campaignId, setCampaignId, storeId, setStoreId, userLocation, setUserLocation, setUserData, userData, brandData, setBrandData, logout, isAuthenticated, setIsAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};