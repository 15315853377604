import PropTypes from "prop-types";
import React from "react";
import { Link, useRouteError } from "react-router-dom";

import { GratifyMainLogo } from "../../../assets/svg";

import { HOMEPAGE } from "../../../routes/RoutesConstant";

import { ErrorContainer } from "./style";

export default function Error() {
  const err = useRouteError();

  return (
    <ErrorContainer role="alert">
      <div className="content">
        <GratifyMainLogo />
        <div className="error-text">Something went wrong!!!!!</div>
        <Link to={HOMEPAGE}><div className="error-message">Go to Home</div></Link>
        <div className="error-data">{err?.data}</div>
      </div>
    </ErrorContainer>
  );
};

Error.propTypes = {
  err: PropTypes.shape({
    data: PropTypes.string,
  }),
};