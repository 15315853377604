import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";

import { ACCOUNT_LOGIN } from "./RoutesConstant";

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to={ACCOUNT_LOGIN} state={{ from: location }} replace />;
};

export default PrivateRoute;
