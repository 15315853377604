import { Alert } from '@mui/material';

import useAlert from '../../../hooks/useAlert';

const AlertPopup = () => {
  const { text, type='success', onClose } = useAlert();

  const colorCode = {
    success: '#2e7d32',
    error: '#d32f2f',
    warning: '#ed6c02',
    info: '#0288d1',
  }

  if (text && type) {
    return (
      <Alert
        severity={type}
        sx={{
          position: 'fixed',
          zIndex: 1000000000000,
          left: '10%',
          right: '10%',
          maxWidth: '45rem',
          top: '2.4rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          color: colorCode[type],
          border: `1px solid ${colorCode[type]}`
        }}
        // onClose={onClose}
      >
        {text}
      </Alert>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
