import styled from "styled-components";

export const StyledSpinner = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
`