import React from "react";

import Footer, { LogoTextFooter } from "../Footer";
import { ProfileTabHeader, ProfileTabSubSectionsHeader, WelcomeHeader } from "../Header";

import AlertPopup from "../../Molecules/AlertPopup";

import { ACCOUNT_LOGIN, ACCOUNT_SIGNUP, PROFILE, PROFILE_DETAILS, REWARDS, WELCOME } from "../../../routes/RoutesConstant";

export default function AppLayout(props) {
  const { children, isPublic } = props;
  let pathname = window.location.pathname;

  function renderHeader() {
    if (pathname.includes(PROFILE_DETAILS))
      return <ProfileTabHeader isProfileTab={false} onBack={true} />
    else if (pathname.includes('/profile/'))
      return <ProfileTabSubSectionsHeader onBack={true} />
    else if (pathname.includes('/profile'))
      return <ProfileTabHeader />
    else if (pathname.includes(REWARDS))
      return null;
    else return <WelcomeHeader />
  }

  const isLoginPage = pathname.includes(ACCOUNT_LOGIN) || pathname.includes(ACCOUNT_SIGNUP);
  const isWelcomePage = pathname.includes(WELCOME);

  return (
    <div style={{ paddingBottom: isWelcomePage ? '0' : (isLoginPage ? '4rem' : '8rem') }}>
      <AlertPopup />

      {/* {renderHeader()} */}

      <div>{children}</div>

      {isWelcomePage ? null : (isLoginPage ?
        <div style={{ position: "fixed", bottom: "2.4rem", left: "50%", transform: "translate(-50%, 0)" }}>{LogoTextFooter()}</div>
        : <Footer isPublic={isPublic} />)}
    </div>
  );
}
