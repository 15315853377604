import { RouterProvider } from 'react-router-dom';

import AppLayout from './components/Organisms/AppLayout';
import { router } from './routes';

import './App.css';
import './global.css';

function App() {
  return (
    <div className="App">
      {/* <AppLayout> */}
        <RouterProvider router={router} />
      {/* </AppLayout> */}
    </div>
  );
}

export default App;
