import React from "react";

import { StyledSpinner } from "./style";

export default function Spinner({ width, height, color = "#fa86ac" }) {
  return (
    <StyledSpinner >
      <div
        className="spinner-border"
        role="status"
        style={{ color: color, width: width, height: height, }}
      ></div>
    </StyledSpinner>
  );
};
