/* eslint-disable import/no-anonymous-default-export */
const appTheme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    // primaryBlue: '#253ECB',
    borderColor: '#E5E5E5',
    lightGrey: '#f7f7f7',
    lightBlack: '#464646',
    lightestGrey: '#7F7F7F',
    primaryColorPink: '#E11F60',
    primaryColorPurple: '#7C47E0',
    errorColor: '#e52424',
    successColor: '#66C732',
    // bgDarken: '#F8F8F8',
    // textPrimary: '#181818',
    // textSecondary: '#5C5C5C',
    // textTertiary: '#747474',
    // icons: '#7C7B7B',
    // stroke: '#D7D7D7',
    // dividers: '#EDEDED',
    // disabledText: '#8A8A8A',
    // disabledBg: '#EFEFEF',
    // white: '#FFFFFF',
    // accent1Primary: '#2D5BFF',
    // accent1Secondary: '#6284FD',
    // accent1Tertiary: '#96ADFF',
    // accent1Quaternary: '#ECF0FF',
    // accent1HoverState: '#1B4AF0',
    // accent1ClickState: '#002ED0',
    // accent2Primary: '#FF7F37',
    // accent2Secondary: '#FC9D68',
    // accent2Tertiary: '#FFD5BE',
    // accent2Quaternary: '#FFECE1',
  },
  breakpoints: {
    xs: "480px",
    sm: "767px", // lesser than this -> phones
    md: "991px", // lesser than this -> tablets, small laptops
    lg: "1199px", // lesser than this laptops, desktops
    xl: "1425px"
  },
};

export default appTheme
